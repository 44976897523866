import { ControllerParams } from '@wix/yoshi-flow-editor';
import { reportEvent } from '@wix/ambassador-automations-esb-v1-report-event-result/http';

export default ({ flowAPI }: ControllerParams) => {
  const { controllerConfig, httpClient } = flowAPI;
  const theButtonInstance = controllerConfig.appParams.instance;

  const sendPushOld = async () => {
    try {
      await httpClient.post('/the-button/v1/push', {});

      console.log('Sent a push');
    } catch (e) {
      console.error('couldnt send push', e);
    }
  };

  const sendPush = async () => {
    try {
      await httpClient.request(
        reportEvent({
          triggerKey: 'the_button',
          payload: {},
        }),
      );

      console.log('Sent a push');
    } catch (e) {
      console.error('couldnt send push', e);
    }
  };

  return {
    pageReady() {
      const setProps = controllerConfig.setProps;
      setProps({
        theButtonInstance,
        handlers: {
          onClick: sendPush,
          onCLickOld: sendPushOld,
        },
      });
    },
  };
};
