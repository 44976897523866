
  import userController from '/home/builduser/work/5615751eb267f18c/crm-the-button/src/components/Button/controller.ts';
  import createControllerWrapper from '@wix/yoshi-flow-editor/runtime/esm/controllerWrapper.js';

  
const wrapController = null;


  
import { createHttpClient } from '@wix/yoshi-flow-editor/runtime/esm/controller/httpClientProp';
    

  

  import { initI18n as initI18n } from '@wix/yoshi-flow-editor/runtime/esm/i18next/init';


  
import { createExperiments, createWidgetExperiments } from '@wix/yoshi-flow-editor/runtime/esm/experiments';
    


  var sentryConfig = {
    DSN: 'https://e6f1a33b99834363bdfae9902de4ef66@sentry.wixpress.com/1902',
    id: 'e6f1a33b99834363bdfae9902de4ef66',
    projectName: 'crm-the-button',
    teamName: 'automations',
    
  };

  var experimentsConfig = {"scopes":["test"],"centralized":true};

  var translationsConfig = {"icuEnabled":true,"defaultTranslationsPath":"/home/builduser/work/5615751eb267f18c/crm-the-button/src/assets/locales/messages_en.json","availableLanguages":["en","uk"]};

  var biConfig = null;

  var defaultTranslations = {"app.widget.welcome":"Welcome","app.settings.label":"Configure Widget","app.settings.tabs.main":"Main","app.settings.tabs.design":"Design","app.settings.defaults.greetingsPretext":"to"};

  var fedopsConfig = null;

  import { createVisitorBILogger as biLogger } from '/home/builduser/work/5615751eb267f18c/crm-the-button/target/generated/bi/createBILogger.ts';

  const controllerOptions = {
    sentryConfig,
    biConfig,
    fedopsConfig,
    experimentsConfig,
    biLogger,
    translationsConfig,
    persistentAcrossPages: false,
    appName: "The Button",
    componentName: "Button",
    appDefinitionId: "84255ec5-af0e-4dbf-aad0-fe55fdbd6bcf",
    componentId: "3380ea52-6dea-449f-a22d-d520e78bf183",
    isUsingAppSettings: false,
    projectName: "crm-the-button",
    defaultTranslations,
    shouldUseEssentials: false,
    withErrorBoundary: false,
    localeDistPath: "assets/locales"
  };

  const _controller = createControllerWrapper(userController, controllerOptions, {
    initI18n,
    createHttpClient,
    createExperiments,
  });

  export const wrap = wrapController;
  export const descriptor = {
    ...controllerOptions,
    id: controllerOptions.componentId,
    widgetType: "WIDGET_OUT_OF_IFRAME",
    controllerFileName: "/home/builduser/work/5615751eb267f18c/crm-the-button/src/components/Button/controller.ts",
  };

  export const controller = _controller
  export default controller;
